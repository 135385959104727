import { HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Home } from './User-components/Home';
import { Admin } from './Admin-components/Admin';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { People } from './People-components/People';
import { Priest } from './Priest-components/Priest';
import { MyPriest } from './User-components/Priest';
import { Prayer } from './User-components/Prayer';
import { Navbar } from './User-components/Navbar';
import { Footer } from './User-components/Footer';
import { ChooseLogin } from './User-components/ChooseLogin';
import { ChooseSignUp } from './User-components/ChooseSignUp';
import { ComingSoon } from './User-components/ComingSoon';
import { AboutUs } from './User-components/AboutUs';
import { Mission } from './User-components/Mission';
import { TermsAndCondition } from './User-components/TermsAndCondition';
import { Privacy } from './User-components/Privacy';
import { FAQs } from './User-components/FAQs';
import { ContactUs } from './User-components/ContactUs';

function MainLayout() {

  const location = useLocation();
  const showNavbarFooterPaths = ['/home', '/mypriest', '/prayerrequest', '/chooselogin', '/choosesignup', '/aboutus', '/mission', '/terms_and_condition', '/privacy_policy', '/faqs', '/contact_us'];
  const showNavbarFooter = showNavbarFooterPaths.includes(location.pathname);

  return (
    <div>
        {showNavbarFooter && <Navbar/>}
        <Routes> 
            <Route path='/' element={<ComingSoon/>} />
            <Route path='/home' element={<Home/>} />
            <Route path='/mypriest' element={<MyPriest/>} />
            <Route path='/prayerrequest' element={<Prayer/>} />
            <Route path='/aboutus' element={<AboutUs/>} />
            <Route path='/contact_us' element={<ContactUs/>} />
            <Route path='/mission' element={<Mission/>} />
            <Route path='/chooselogin' element={<ChooseLogin/>} />
            <Route path='/choosesignup' element={<ChooseSignUp/>} />
            <Route path='/terms_and_condition' element={<TermsAndCondition/>} />
            <Route path='/faqs' element={<FAQs/>} />
            <Route path='/privacy_policy' element={<Privacy/>} />
            <Route path='/adminsample/*' element={<Admin/>} />
            <Route path='/people/*' element={<People/>} />
            <Route path='/priest/*' element={<Priest/>} />
            <Route path='*' element={<NotFound />} />
        </Routes>
        {showNavbarFooter && <Footer />}
    </div>
  );
}

function App() {
  return (
    <HashRouter>
      <MainLayout />
    </HashRouter>
  );
}

export default App;

const NotFound = () => {
  return (
    <div className="text-center mt-5">
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      
      <div class="main" style={{margin: "0 auto"}}>
        <div class="dog">
          <div class="dog__paws">
            <div class="dog__bl-leg leg">
              <div class="dog__bl-paw paw"></div>
              <div class="dog__bl-top top"></div>
            </div>
            <div class="dog__fl-leg leg">
              <div class="dog__fl-paw paw"></div>
              <div class="dog__fl-top top"></div>
            </div>
            <div class="dog__fr-leg leg">
              <div class="dog__fr-paw paw"></div>
              <div class="dog__fr-top top"></div>
            </div>
          </div>

          <div class="dog__body">
            <div class="dog__tail"></div>
          </div>

          <div class="dog__head">
            <div class="dog__snout">
              <div class="dog__eyes">
                <div class="dog__eye-l"></div>
                <div class="dog__eye-r"></div>
              </div>
            </div>
          </div>
          <div class="dog__head-c">
            <div class="dog__ear-r"></div>
            <div class="dog__ear-l"></div>
          </div>
        </div>
      </div>


    </div>
  );
};